<template>
  <div>
    <AccountDetails />
  </div>
</template>

<script>
import AccountDetails from '@/components/AccountDetails.vue';
export default {
  name: 'account-details',
  components: {
    AccountDetails,
  },
  data() {
    return {
      countries: [
        'Wales',
        'England',
        'Scotland',
        'Northern Ireland',
        'Rep. Ireland'
      ]
    }
  },
}
</script>

<style>

</style>