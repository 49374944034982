<template>
<!-- This is a mock/test page being used for all apps -->
<!-- User account details -->
  <v-card flat class="pa-5">
    <v-card-title class="text-center py-6">
      <span class="text-h5 font-weight-bold">
        My Account Details
      </span>
      <v-img id="profile-img" src="../assets/profile-img.svg"></v-img>
    </v-card-title>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="secondary"
      show-arrows
      center-active
    >
      
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card outlined class="mt-5">
          <v-card-title>Contact details</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="First name"
                  v-model="firstName"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8 mt-sm-0" cols="12" sm="6">
                <v-text-field
                  v-model="surname"
                  label="Surname"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="6">
                <v-text-field
                  label="Telephone (Optional)"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="6">
                <v-text-field
                  label="Email"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="12">
                <v-text-field
                  label="Address (Optional)"
                  outlined
                ></v-text-field>
              </v-col>
              <!-- <v-col class="mt-n8" cols="12" sm="6">
                <v-select
                  :items="countries"
                  label="Outlined style"
                  outlined
                ></v-select>
              </v-col> -->
              <v-col class="mt-n8" cols="12" sm="6">
                <v-text-field
                  label="Postcode (Optional)"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col v-if="$store.state.landlordLogin" class="mt-n6" cols="12">
                <v-alert
                  v-if="!consent"
                  color="red"
                  dark
                  icon="mdi-alert"
                  border="left"
                  prominent
                >
                  <p class="text-body-1">Your agent can present potential losses due to tenants not paying rent and the expense on proceedings, and available insurance products to protect you.  </p>
                </v-alert>
                <div>
                  <span class="text-h6 font-weight-bold">Do you give consent for your agent to share this information?</span>
                  <v-switch inset color="green" :label="consentText" v-model="consent"></v-switch>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions 
            class="pa-4"
            :class="[$store.state.landlordLogin ? 'black' : '']"  
          >
            <v-btn depressed color="primary" class="py-5" width="200">
              <span class="white--text">Update</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card outlined class="mt-5">
          <v-card-title primary-title>
            Account Password
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Current Password"
                  outlined
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-n8" cols="12" sm="6">
                <v-text-field
                  label="New Password"
                  outlined
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="6">
                <v-text-field
                  label="Confirm"
                  outlined
                  type="password"
                ></v-text-field> 
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-btn depressed color="primary" class="py-5" width="200">
              <span class="white--text">Update</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card outlined class="mt-5">
          <v-card-title primary-title>
            Deactivate account
          </v-card-title>
          <v-card-text>
            <p>Deactivating your account will result in the loss of all saved data.</p>
            <p>Are you sure you want to continue?</p>
            <v-switch
              v-model="deactivate"
              inset
              label="Deactivate"
              color="primary"
            ></v-switch>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-btn depressed color="primary" class="py-5" width="200">
              <span class="white--text">Update</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card outlined class="mt-5">
          <v-card-title primary-title>
            Theme color picker
            <v-spacer></v-spacer>
            <v-switch color="primary" inset :label="appThemeLight ? 'Switch to Dark' : 'Switch to Light'" v-model="appThemeLight"></v-switch>
          </v-card-title>
          <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
              Primary color
              <v-color-picker
                v-model="primaryColor"
                class="my-2"
                canvas-height="100"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" sm="3">
              Secondary color
              <v-color-picker
                v-model="secondaryColor"
                class="my-2"
                canvas-height="100"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" sm="3">
              Tertiary color
              <v-color-picker
                v-model="tertiaryColor"
                class="my-2"
                canvas-height="100"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" sm="3">
              Navigation & header color
              <v-color-picker
                v-model="navbarColor"
                class="my-2"
                canvas-height="100"
              ></v-color-picker>
            </v-col>
          </v-row>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-btn @click="updateColors" :loading="loading" depressed color="primary" class="py-5" width="200">
              <span class="white--text">Update</span>
            </v-btn>            
            <v-btn @click="setDefaultColors" :loading="loadingSetDefault" outlined ssed color="primary" class="py-5" width="200">
              <span>Set to default</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'

  export default {
    name: 'accountDetailsComponents',
    data () {
      return {
        loading: false, 
        loadingSetDefault: false,
        consent: false,
        tab: null, //default is null 
        firstName: 'Glyn',
        surname: 'Trott',
        items: [
          { tab: 'Contact details'},
          { tab: 'Update password'},
          { tab: 'Deactivate account'},
          { tab: 'App Theme'},
        ],
        deactivate: false,
        // Color pickers
        appThemeLight: true,
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
        navbarColor: ''
      }
    },
    created() {
      this.fetchThemeColors()
    },
    methods: {
      fetchThemeColors() {
        this.primaryColor = this.$vuetify.theme.themes.light.primary
        this.secondaryColor = this.$vuetify.theme.themes.light.secondary
        this.tertiaryColor = this.$vuetify.theme.themes.light.tertiary
        this.navbarColor = this.$vuetify.theme.themes.light.navbarColor
      },
      updateColors() {
        this.loading = true
        setTimeout( () => {
          this.loading = false
          this.$vuetify.theme.themes.light.primary = this.primaryColor
          this.$vuetify.theme.themes.light.secondary = this.secondaryColor
          this.$vuetify.theme.themes.light.tertiary = this.tertiaryColor
          this.$vuetify.theme.themes.light.navbarColor = this.navbarColor
        },1000)
      },
      setDefaultColors() {
        this.loadingSetDefault = true
        setTimeout( () => {
          this.loadingSetDefault = false
          this.appThemeLight = true
          if(this.tenantUser) {
            this.$vuetify.theme.themes.light.primary = '#00ded6'
            this.$vuetify.theme.themes.light.secondary = '#262637'
            this.$vuetify.theme.themes.light.tertiary = '#9E9E9E'
            this.$vuetify.theme.themes.light.navbarColor = '#ffffff'
          } else if(this.landlordUser) {
              this.$vuetify.theme.themes.light.primary = '#c3d423' 
              this.$vuetify.theme.themes.light.secondary = '#000000' 
              this.$vuetify.theme.themes.light.tertiary = '#9E9E9E' 
              this.$vuetify.theme.themes.light.navbarColor = '#000000'
            }
          this.fetchThemeColors()
        },1000)
      },
    },
    computed: {
      consentText() {
        if(this.consent) return 'Yes, I consent.'
        return 'No, I do not consent.'
      },
      ...mapState({
        tenantUser: state => state.tenantUser,
        landlordUser: state => state.landlordUser,
        buyerUser: state => state.buyerUser,
        vendorUser: state => state.vendorUser,
      })
    },
    watch: {
      appThemeLight() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      } 
    }
  }
</script>

<style scoped>
#profile-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
  height: auto;
  opacity: 0.3;
}
</style>